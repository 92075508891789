@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 10% 3.9%;
    --radius: 0.5rem;
    --chart-1: 239, 84%, 67%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 239, 84%, 67%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

body {
  /* Prevents a glitch from @radix-ui/react-dialog that sets this to none. */
  pointer-events: initial !important;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #b4c6fc;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #b4c6fc,
    0 0 5px #b4c6fc;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

/* React Day Picker Overrides */
:root {
  --rdp-accent-color: #4f46e5; /* indigo-600 */
  --rdp-background-color: #eff6ff; /* blue-50 */
}

/* Custom Field Value - Braft Editor CSS overrides */
.media-wrap.float-left {
  margin-right: 10px;
}
.media-wrap.float-right {
  margin-left: 10px;
}
.media-wrap img {
  margin-bottom: 0;
  margin-top: 0;
}

/* Custom Braft Editor styles */
.bf-container {
  border: 1px solid #d1d5db;
}
.bf-container .bf-content {
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  font-size: 14px;
}
.bf-container a.bf-link {
  color: #4f46e5;
  text-decoration: none;
}
.bf-container .public-DraftEditor-content {
  padding: 0 12px !important;
  word-break: normal !important;
}
.bf-container .public-DraftStyleDefault-block {
  margin: 1em 0;
}
.bf-container .control-item.media {
  background-image: url("/images/icons/photograph.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 18px auto;
}
.bf-container .bfi-media:before {
  content: none !important;
}
.bf-container .bf-content h1 {
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 2rem;
}
.bf-container .bf-content h2 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}
.bf-container .bf-content h3 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
}
.bf-container .headings-dropdown ul.menu li:nth-child(4),
.bf-container .headings-dropdown ul.menu li:nth-child(5),
.bf-container .headings-dropdown ul.menu li:nth-child(6) {
  display: none;
}

/* Custom Time Picker styles */
.react-time-picker__wrapper {
  padding: 5px 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #d1d5db !important;
}
.react-time-picker--error > .react-time-picker__wrapper {
  border: 1px solid #fca5a5 !important;
}
.react-time-picker__wrapper:focus-within {
  border: 2px solid #6366f1 !important;
}
.react-time-picker__inputGroup__input:focus {
  box-shadow: none;
}
.react-time-picker__clear-button {
  visibility: hidden;
}

/* Manual styling for user/cart links on medium sized screens */
#md-cart-user-links {
  display: none;
}
@media (min-width: 768px) {
  #md-cart-user-links {
    display: flex;
  }
}

@keyframes progress {
  0% {
    left: -50%;
    width: 30%;
  }
  50% {
    width: 60%;
  }
  100% {
    left: 100%;
    width: 30%;
  }
}

.animate-progress {
  animation: progress 2s ease-in-out infinite;
}

/* Custom React Phone Number Input styles */
/* CSS variables. */
:root {
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
}

/* The phone number input stretches to fill all empty space */
/* The phone number input should shrink to make room for the extension input */
.PhoneInputInput {
  flex: 1;
  min-width: 0;
  border: none;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 8px 12px 8px 6px;
  color: #1f2937;
}
@media screen and (min-width: 641px) {
  .PhoneInputInput {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
.PhoneInputInput:focus {
  box-shadow: none;
}

.PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
}

.PhoneInputCountryIcon--square {
  width: var(--PhoneInputCountryFlag-height);
}

/* Fixes weird vertical space above the flag icon. */
/* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
/* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
  Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}

.PhoneInputInternationalIconPhone {
  opacity: var(--PhoneInputInternationalIconPhone-opacity);
}

.PhoneInputInternationalIconGlobe {
  opacity: var(--PhoneInputInternationalIconGlobe-opacity);
}

/* Styling native country `<select/>`. */
.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin: 0 6px 0 12px;
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountrySelect[disabled],
.PhoneInputCountrySelect[readonly] {
  cursor: default;
}

.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: var(--PhoneInputCountrySelectArrow-width);
  height: var(--PhoneInputCountrySelectArrow-width);
  margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
  border-style: solid;
  border-color: var(--PhoneInputCountrySelectArrow-color);
  border-top-width: 0;
  border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  border-left-width: 0;
  border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
  transform: var(--PhoneInputCountrySelectArrow-transform);
  opacity: var(--PhoneInputCountrySelectArrow-opacity);
}
